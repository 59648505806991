body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom icons stuff */
@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?4hmjjz');
    src: url('fonts/icomoon.eot?4hmjjz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4hmjjz') format('truetype'),
    url('fonts/icomoon.woff?4hmjjz') format('woff'),
    url('fonts/icomoon.svg?4hmjjz#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cleaning:before {
    content: "\e900";
}

.icon-cleaning_cart:before {
    content: "\e901";
}

/* End: custom icons stuff */

a {
    color: #519E96;
}

.MuiSvgIcon-root {
    height: 5em;
    width: 5em;
}

.MuiMenu-list {
    width: 300px;
}

/*.MuiTypography-h5 {*/
/*  color: blue;*/
/*  font-size: 2.6em;*/
/*}*/
