#services h5 {
    margin: 20px 10px 0;
    text-align: center;
}

#services h4, #contact h4 {
    padding-top: 40px;
}

#services {
    min-height: calc(100vh - 100px);
    padding-bottom: 50px;
}

#services strong {
    color: #519e96;
}

#contact strong {
    color: #f4ffef;
}

#contact {
    min-height: calc(100vh - 100px);
    background-color: #0f232d;
    padding: 4px
}

.hero-blurb {
    margin-top: 25vh;
    z-index: 2;
    width: 700px;
    padding-left: 10px;
    padding-right: 10px;
}

.hero-blurb h4 {
    margin-bottom: 40px;
    font-weight: bold;
    color: white;
}

.caption {
    font-size: 20px;
}

.hero-blurb button {
    /*background-color: #172933;*/
    background-color: #207a1f;
    min-width: 200px;
    line-height:3.5;
}

.hero-blurb button:hover {
    background-color: #207a1f;
    min-width: 200px;
    line-height:3.5;
}

.hero-blurb button a {
    color: white;
}

#hero-shadow {
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: calc(100vh);
    z-index: 0;
    background-color: rgba(14,30,37,0.7);
}

i {
    font-size: 100px;
}

i.fa-inverse {
    color: #519E96;
    margin-top: 15px;
}

@media only screen and (min-width: 600px) {
    .hero-blurb h5 {
        width: 450px;
        margin-left: -30px;
    }
}
